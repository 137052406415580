export default function LazyLoading() {
  return (
    <div
      aria-busy="true"
      className="flex items-center justify-center min-h-screen text-center"
      style={{ perspective: "10cm" }}
    >
      <img
        src="/logo.svg"
        alt="loading"
        className="w-[20vmin] h-[20vmin] spin-3d opacity-10"
      />
    </div>
  );
}
