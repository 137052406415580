import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [open, setOpen] = useState(false);
  const hide = () => {
    document.scrollingElement.classList.remove("hide-scroll");
    setOpen(false);
  };
  const toggle = () => {
    setOpen(wasOpen => {
      document.scrollingElement.classList[wasOpen ? "remove" : "add"](
        "hide-scroll",
      );
      return !wasOpen;
    });
  };

  useEffect(() => hide, []);

  const mainLinks = [
    { to: "/legacy", body: "Legacy" },
    { to: "/collections", body: "Collections" },
    { to: "/news", body: "News" },
    { to: "/contact", body: "Contact Us" },
  ];
  return (
    <header className="fixed top-0 left-0 z-10 w-full">
      <div className="flex items-center justify-between w-full mx-auto max-w-7xl">
        <div className="relative">
          <Link
            to="/"
            className="absolute top-0 left-0 z-20 m-4 overflow-hidden w-14 h-14 md:w-20 md:h-20 rounded-2xl backdrop-blur-sm"
            onClick={hide}
          >
            <img
              src="/logo_circ.svg"
              className="object-contain w-full h-full"
              alt="Logo"
            />
          </Link>
        </div>
        <div className="relative">
          <button
            className="absolute top-0 right-0 z-20 w-8 h-8 m-6 transition-transform md:w-10 md:h-10 active:scale-90"
            onClick={toggle}
            aria-expanded={open}
          >
            <b
              className="absolute left-0 w-full h-px transition-transform duration-500 origin-center bg-black top-1/2"
              style={{
                transform: open ? "rotateZ(45deg)" : "translateY(-8px)",
                boxShadow: "1px 1px 1px #fffc",
              }}
            ></b>
            <b
              className="absolute left-0 w-full h-px transition-all duration-500 delay-200 origin-center bg-black top-1/2"
              style={{
                transform: open ? "rotateZ(45deg)" : "",
                opacity: open ? 0 : 1,
                boxShadow: "1px 1px 1px #fffc",
              }}
            ></b>
            <b
              className="absolute left-0 w-full h-px transition-transform duration-500 delay-100 origin-center bg-black top-1/2"
              style={{
                transform: open ? "rotateZ(135deg)" : "translateY(8px)",
                boxShadow: "1px 1px 1px #fffc",
              }}
            ></b>
          </button>
        </div>
      </div>

      <nav
        className="fixed uppercase flex items-stretch top-0 w-full h-full bg-stone-400 delay-100 transition-[left] duration-500 text-stone-500 pt-24 overflow-auto min-scroll"
        role={open ? "dialog" : "none"}
        style={{ left: open ? 0 : "100%" }}
      >
        <div className="flex flex-col items-stretch mx-auto max-w-7xl grow">
          {mainLinks.map((i, idx) => (
            <Link
              key={i.to}
              to={i.to}
              className="relative py-4 pl-8 mx-8 text-4xl transition-colors duration-300 ease-in border-t first:border-t-0 md:py-6 md:pl-16 md:text-5xl lg:text-7xl group border-stone-500 hover:text-stone-800 slide-in"
              style={{ animationDelay: idx * 500 + "ms" }}
              onClick={hide}
            >
              <span className="absolute transition-opacity duration-700 opacity-0 -left-[.8rem] group-hover:opacity-100">
                &rarr;
              </span>
              {i.body}
            </Link>
          ))}

          <div className="flex-1"></div>

          <aside className="pl-8 mx-8 mt-10 mb-10 mr-8 text-2xs md:text-xs md:pl-16">
            <div className="text-lg border-b md:text-2xl border-stone-500">
              Textile Arts and Modern Arts Pavilion
            </div>
            <div className="flex flex-wrap my-4 gap-x-4 gap-y-2" onClick={hide}>
              <a href="https://www.facebook.com/peacegallery.art">Facebook</a>
              <a href="https://www.instagram.com/peacegallery.art">Instagram</a>
            </div>
            <div>&copy; Peace Gallery. All Rights Reserved.</div>
          </aside>
        </div>
      </nav>
    </header>
  );
}
