import "./Vision.css";
import Reveal from "components/Reveal";

export default function Vision() {
  return (
    <Reveal
      className="self-stretch w-full flex flex-col text-lg md:text-2xl leading-relaxed md:leading-relaxed py-8 min-h-[80vh] text-center uppercase anim-white-to-black px-4"
      rootMargin="-100px"
    >
      <b className="flex-1"></b>
      <h2 className="text-5xl">Vision</h2>
      <p className="w-full max-w-3xl mx-auto my-8">
        Incubate and support artists by putting on shows, promoting and selling
        artworks.
      </p>
      <p className="w-full max-w-3xl mx-auto">
        Provide services such as bespoke / couture / artist collab in order to
        redefine the future of arty wearable at large.
      </p>
      <b className="flex-1"></b>
    </Reveal>
  );
}
