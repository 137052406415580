import Reveal from "components/Reveal";
import Arrow from "components/Arrow";

import List from "../Collections/List";
import Vision from "./Vision";

export default function Home() {
  return (
    <main>
      <Reveal className="min-h-[90vh] flex flex-col justify-evenly items-stretch text-center text-stone-800 uppercase">
        <aside
          className="text-lg leading-none tracking-wider md:text-xl xl:text-2xl fade-in"
          style={{ animationDelay: "0.8s" }}
        >
          <div className="opacity-50">Objects Of</div>
          <strong>Desire</strong>
        </aside>
        <h1 className="px-4 slide-in" style={{ animationDuration: "1.5s" }}>
          <div className="text-2xl md:text-4xl xl:text-5xl">
            Textile <span className="opacity-50">Arts and</span> Modern
            <span className="opacity-50"> Arts Pavilion</span>
          </div>
          <div className="mt-4 text-5xl md:text-8xl xl:text-9xl">
            Peace Gallery
          </div>
        </h1>
      </Reveal>

      <Arrow className="absolute left-1/2" />

      <section
        role="none"
        className="h-[65vh] min-h-[300px]"
        style={{ background: "url(/bg_grass.jpg) top center/cover no-repeat" }}
      ></section>

      <Reveal className="px-8 text-center uppercase min-h-[70vh] my-[10vh] flex flex-col justify-evenly mx-auto w-full max-w-3xl leading-relaxed md:leading-loose text-2xl md:text-3xl">
        <p className="slide-in" style={{ animationDuration: "2s" }}>
          A New Beginning...
        </p>
        <p
          className="my-8 slide-in"
          style={{ animationDelay: "0.5s", animationDuration: "2s" }}
        >
          SPACE exploring the textile art, heritage renew and mind wellness
          wisdom
        </p>
        <p
          className="my-8 slide-in"
          style={{ animationDelay: "1s", animationDuration: "2s" }}
        >
          In the PEACE of cultivating slow luxury and creativity
        </p>
      </Reveal>

      <Arrow className="absolute left-1/2" />

      <Reveal className="text-center uppercase my-[25vh] flex flex-col justify-evenly mx-auto w-full max-w-3xl">
        <h2 className="text-4xl leading-normal fade-in md:leading-normal md:text-6xl">
          The
          <br />
          Collection
        </h2>
      </Reveal>

      <List />

      <Vision />
    </main>
  );
}
