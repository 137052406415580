import { useEffect, useRef, useState } from "react";

export default function Reveal({ children, rootMargin = "-20px", ...rest }) {
  const nodeRef = useRef();
  const [role, setRole] = useState("presentation");

  useEffect(() => {
    const { current } = nodeRef;
    if (!current) return;

    const obs = new IntersectionObserver(
      ([n]) => {
        setRole(n.isIntersecting ? "presentation" : "none");
      },
      { rootMargin },
    );
    obs.observe(current);

    return () => {
      obs.unobserve(current);
    };
  }, []);

  return (
    <div ref={nodeRef} {...rest} role={role}>
      {children}
    </div>
  );
}
