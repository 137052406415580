import { products } from "network/data";
import Tile from "./Tile";

export default function List() {
  return (
    <div className="grid w-full max-w-7xl grid-cols-1 sm:grid-cols-2 gap-[5vw] mx-auto px-4 lg:px-12 sm:pb-[25vh] md:pb-[50vh]">
      {products.map((i, idx) => (
        <Tile
          key={i.id}
          product={i}
          className={
            "relative " + (idx % 2 === 0 ? "" : "sm:top-[25vh] md:top-[50vh]")
          }
        />
      ))}
    </div>
  );
}
