import { Link } from "react-router-dom";

import Reveal from "components/Reveal";

export default function Tile({ product, className = "" }) {
  return (
    <Reveal className={className}>
      <Link
        to={"/collection/" + product.id}
        className="block p-[5%] md:p-[10%] uppercase transition-colors duration-300 border zoom-in group border-stone-100 hover:border-black text-black"
      >
        <div className="overflow-hidden">
          <img
            src={product.carousel[0].url}
            className="block w-full min-h-[100px] max-w-full transition-transform duration-700 scale-105 group-hover:scale-100 bg-stone-100"
            alt=""
          />
        </div>
        <h2 className="my-4 text-2xl tracking-wider md:my-8 md:text-4xl">
          {product.title}
        </h2>
        <div className="flex items-center justify-between gap-1 pb-8 mb-8 text-xs transition-colors duration-300 border-b border-black md:text-base sm:border-stone-100 group-hover:border-black">
          <div>{product.subtitle}</div>
          {product.subtitle2 ? (
            <div className="text-stone-500">{product.subtitle2}</div>
          ) : null}
        </div>
      </Link>
    </Reveal>
  );
}
