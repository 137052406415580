import { Link } from "react-router-dom";
import Reveal from "./Reveal";

export default function Footer() {
  const mainLinks = [
    { to: "/legacy", body: "Legacy" },
    { to: "/collections", body: "Collections" },
    { to: "/news", body: "News" },
    { to: "/contact", body: "Contact Us" },
  ];
  const LINK = "hover:text-white";
  return (
    <Reveal rootMargin="-100px" className="uppercase bg-black text-stone-500">
      {/* <aside className="fade-out h-[200px] bg-gradient-to-b from-transparent to-black"></aside> */}

      <footer className="w-full pt-24 pb-10 mx-auto max-w-7xl">
        {mainLinks.map((i, idx) => (
          <Link
            key={i.to}
            to={i.to}
            className="relative block py-4 pl-8 mx-8 text-4xl transition-colors duration-300 ease-in border-t first:border-t-0 text-stone-600 lg:py-8 md:py-6 md:pl-16 md:text-6xl lg:text-7xl group border-stone-500 hover:text-white focus:text-white slide-in"
            style={{ animationDelay: idx * 700 + "ms" }}
          >
            <span className="absolute transition-opacity duration-700 opacity-0 -left-[.8rem] group-hover:opacity-100">
              &rarr;
            </span>
            {i.body}
          </Link>
        ))}

        <div
          className="pl-8 mx-8 mt-10 mr-8 text-2xs sm:text-sm md:pl-16 fade-in"
          style={{ animationDelay: ".5s" }}
        >
          <div className="py-2 text-2xl border-b border-stone-500 text-stone-600">
            Contacts
          </div>
          <div className="flex flex-col justify-between gap-1 my-4 md:flex-row">
            <div className="flex flex-wrap gap-4">
              <a
                href="https://www.facebook.com/peacegallery.art"
                className={LINK}
              >
                Facebook
              </a>
              <a
                href="https://www.instagram.com/peacegallery.art"
                className={LINK}
              >
                Instagram
              </a>
              <a
                href="https://www.ctnr.net/chat/?id=peacegalleryart"
                className={LINK}
                title="Chat with Us"
              >
                _
              </a>
            </div>
            <div>&copy; Peace Gallery. All Rights Reserved.</div>
          </div>
        </div>
      </footer>
    </Reveal>
  );
}
