export type CarouselItems = {
  url: string;
};
export type TableItems = {
  th: string;
  td?: string;
  url?: string;
};
export type Product = {
  id: string;
  title: string;
  subtitle?: string;
  subtitle2?: string;
  carousel: CarouselItems[];
  intro?: string;
  table?: TableItems[];
};

const CDN = "https://f004.backblazeb2.com/file/pcglry/";

export const products: Product[] = [
  {
    id: "piacenza",
    title: "PIACENZA",
    subtitle: "1733",
    subtitle2: "",
    carousel: [
      {
        url: CDN + "gallery1_piacenza_1.jpeg",
      },
      {
        url: CDN + "gallery1_piacenza_2.jpg",
      },
      {
        url: CDN + "gallery1_piacenza_3.jpg",
      },
      {
        url: CDN + "gallery1_piacenza_4.jpeg",
      },
    ],
    intro: `
![logo](${CDN}gallery1_piacenza.png#width=400px)

Entrepreneurship and determination, curiosity and courage have always distinguished the Piacenza family. Tradition of attention to quality and authenticity are voiced in garments that interpret timeless classics with an elegant, relaxed and modern attitude. 

Precious and exclusive., which summarizes the soul of the Piacenza Vicuña fabric, one of the very few companies in the world capable of obtaining and processing this rare fiber.`,
    table: [
      {
        th: "Website",
        td: "piacenza1733.com",
        url: "https://www.piacenza1733.com/en/home_en/",
      },
      {
        th: "Facebook",
        td: "@PIACENZA1733",
        url: "https://www.facebook.com/PIACENZA1733/",
      },
      {
        th: "Instagram",
        td: "@PIACENZA1733",
        url: "https://www.instagram.com/PIACENZA1733/",
      },
    ],
  },
  {
    id: "jabot",
    title: "Jabot",
    subtitle: "Think Scarf",
    subtitle2: "Think Jabot",
    carousel: [
      { url: CDN + "gallery2_jabot_1.jpg" },
      { url: CDN + "gallery2_jabot_2.jpg" },
      { url: CDN + "gallery2_jabot_3.jpg" },
      { url: CDN + "gallery2_jabot_4.JPG" },
    ],
    intro: `
![logo](${CDN}gallery2_jabot.webp#width=400px)

> It's all about craftsmanship. Craftsmanship is not only creating to fulfil other people, but also creating to fulfil yourself.

JABOT dwells upon the value of how scarfs are made - a fraught issue in this age of fast-fashion, waste, and the treatment of workers. This all of these designers consciously made that link in their  own ways, syncing the values of timeless luxury, current relevance, and the work of human hands. 
- Handmade
- Recycle
- Vegan
- Substantial fashion accessories
`,
    table: [
      { th: "Website", td: "ja-bot.com", url: "http://www.ja-bot.com" },
      {
        th: "Facebook",
        td: "@jabotier",
        url: "https://www.facebook.com/jabotier",
      },
      {
        th: "Instagram",
        td: "@jabotier",
        url: "https://www.instagram.com/jabotier",
      },
    ],
  },
];
