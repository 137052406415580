export default function Arrow({ className = "" }) {
  return (
    <aside role="none" className={className}>
      <div className="absolute -top-10 md:-top-20 w-5 h-20 md:h-40 skew-y-[45deg] border-b border-r border-black -left-5">
        <div className="absolute inset-0 border-b border-r top-px border-stone-300"></div>
      </div>
      <div className="absolute -top-10 md:-top-20 left-0 w-5 h-20 md:h-40 border-b skew-y-[-45deg] border-black">
        <div className="absolute inset-0 border-b bottom-px border-stone-300"></div>
      </div>
    </aside>
  );
}
